/** @file Provides a simple, native toast library for Modal. */

import { createToaster } from "@melt-ui/svelte";
import { HTTPError, TimeoutError } from "ky";

export type Toast = {
  kind: "info" | "success" | "error";
  message: string;
  action?: string;
  onAction?: () => void;
};

const {
  elements: { title },
  helpers: { addToast, removeToast },
  states: { toasts },
  actions: { portal },
} = createToaster<Toast>();

export { removeToast, toasts, portal, title };

export function makeToast(toast: Toast, duration: number = 3000) {
  addToast({
    data: toast,
    closeDelay: duration,
  });
}

export async function makeErrorToast(error: Error) {
  makeToast(
    {
      kind: "error",
      message: await normalizeError(error),
    },
    5000,
  );
}

/** Normalize an API error into a human-readable message. */
export async function normalizeError(error: Error): Promise<string> {
  let message = error.message;
  if (error instanceof TimeoutError) {
    message = "Network error: " + error.message;
  } else if (error instanceof HTTPError) {
    // Server errors have a JSON payload from Sanic. Network errors are not `HTTPError`.
    const payload: {
      description: string;
      status: number;
      message: string;
    } = await error.response.json();
    message = payload.description;
    if (payload.message && payload.message !== "None") {
      message += ": " + payload.message;
    }
  }
  return message;
}
